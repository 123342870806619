import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content, Map } from "page_components/contact"

const Contact = ({ location }) => {
  const title = "Контакт"

  return (
    <Layout
      location={location}
      seo={{
        title: title,
        description:
          "Купуєте квартиру? Продаж землі? Співпраця? У Trust Investment ми вважаємо, що незалежно від питання, про це варто говорити. Поговоримо про плідну співпрацю!",
      }}
    >
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content />
      <Map />
    </Layout>
  )
}

export default Contact
